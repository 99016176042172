export const emailRules = [
    v => !!v || 'E-mail is required',
    v => (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Please enter a valid email address'
]
export const componentNameRules = [
  v => !!v || 'Name is required',
  v => (v && /^[a-z][a-z0-9_-]+$/.test(v)) || "Name must be lowercase, start with a letter and can only contain letters, numbers, '-', '_'"
]
export const componentFieldNameRules = [
  v => !!v || 'Name is required',
  v => (v && /^[a-z][a-z0-9_]+$/.test(v)) || "Name must be lowercase, start with a letter and can only contain letters, numbers, '_'"
]
export const pinRules = [
  v => parseInt(v) || 'PIN must be number',
  v => !!v || 'Pin is required',
  v => v.length === 4 || 'Pin must be 4 characters',
]
